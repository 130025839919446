import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Lessons from './Lessons'
import jwt_decode from 'jwt-decode'

const isLoginExpired = () => {
  const jwt = localStorage.getItem('jwt')
  const user = localStorage.getItem('user')
  if (!jwt || !user) return true

  const decoded = jwt_decode(jwt)
  if (decoded.exp * 1000 < Date.now()) return true

  return false
}

const MainApp = ({ page = <Lessons type="theme" /> }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoginExpired()) navigate('/prihlaseni', { replace: true })
  }, [navigate])

  return (
    <main className="flex-grow flex">
      <div className="flex flex-grow max-w-4xl hd:max-w-7xl mx-auto sm:py-4 sm:px-4">
        <div className="flex-grow flex flex-col bg-white p-4 shadow sm:rounded-lg">
          {page}
        </div>
      </div>
    </main>
  )
}

export default MainApp
