import { useQuery, gql } from '@apollo/client'
import { ChevronDownIcon, XCircleIcon } from '@heroicons/react/20/solid'
import Spinner from './Spinner'

const DOCUMENTS = gql`
  query {
    filteredDocuments {
      name
      difficulty
      type
      file {
        data {
          attributes {
            name
            url
            previewUrl
          }
        }
      }
    }
  }
`

const DownloadsTable = () => {
  const { loading, error, data } = useQuery(DOCUMENTS)

  if (error)
    return (
      <div className="px-4 flex items-center justify-center h-full">
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {error && error.message === 'NO_DOCUMENTS_FOUND'
                  ? 'Pro zaplacené úrovně nebyly nalezeny žádné dokumenty'
                  : 'Chyba'}
              </h3>
            </div>
          </div>
        </div>
      </div>
    )

  if (loading)
    return (
      <div className="px-4 flex items-center justify-center h-full">
        <Spinner />
      </div>
    )

  return (
    <div className="overflow-hidden ring-1 ring-black ring-opacity-5 rounded-lg">
      <table className="w-full divide-y divide-gray-100">
        <thead className="bg-gray-100">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-xs sm:text-sm font-medium text-gray-900"
            >
              Název
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-medium text-gray-900"
            >
              Úroveň
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-medium text-gray-900"
            >
              Typ
            </th>
            <th
              scope="col"
              className="relative py-3.5 pl-3 pr-4"
            >
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 bg-white">
          {data.filteredDocuments.map((doc) => (
            <tr key={doc.name}>
              <td className="pl-4 py-3 pr-3 text-xs sm:text-sm font-medium text-gray-700">
                {doc.name}
              </td>
              <td className="hidden sm:table-cell px-3 py-3 text-sm text-gray-500">
                {doc.difficulty}
              </td>
              <td className="hidden sm:table-cell px-3 py-3 text-sm text-gray-500">
                {doc.type}
              </td>
              <td className="sm:w-auto relative py-3 pl-3 pr-4 text-right text-sm font-medium">
                <a
                  href={doc.file.data.attributes.url}
                  target="_blank"
                  className="whitespace-nowrap inline-flex items-center justify-center px-2 py-1 border border-transparent rounded-md shadow-sm text-xs sm:text-sm font-medium text-white bg-p hover:bg-pd"
                >
                  Stáhnout
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const Downloads = () => {
  return (
    <>
      <section className="text-center py-16 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-gray-900">
          Ke stažení
        </h1>
        <p className="mt-4 max-w-xl mx-auto text-base text-gray-500">
          Dokumenty ke stažení pro zakoupené úrovně
        </p>
      </section>

      <section className="min-h-[18rem] pt-6 pb-4 z-10 border-t border-t-gray-200">
        <DownloadsTable />
      </section>
    </>
  )
}

export default Downloads
