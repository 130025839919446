import React from 'react'
import { NavLink } from 'react-router-dom'

import courses from 'images/courses.jpg'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Courses = () => {
  return (
    <div className="relative flex-1 lg:grid lg:grid-cols-2">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:fixed lg:inset-y-0 lg:left-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover lg:h-full"
            src={courses}
            alt=""
          />
        </div>
      </div>
      <div className="relative bg-white lg:col-start-2">
        <div className="mx-auto max-w-prose px-4 pb-16 pt-12 text-base sm:px-6 sm:pt-16 lg:ml-6 lg:mr-auto lg:max-w-lg lg:px-8">
          <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            Online kurzy
          </h3>
          <p className="mt-8 text-lg text-gray-500">
            V naší nabídce najdete 4 online kurzy rozdělené podle úrovní:
          </p>
          <div className="prose prose-indigo mt-5 text-gray-500">
            <ul role="list">
              <li>Online kurz angličtiny LIVE, úroveň úplný začátečník A1</li>
              <li>Online kurz angličtiny LIVE, úroveň začátečník A2</li>
              <li>Online kurz angličtiny LIVE, úroveň mírně pokročilý B1</li>
              <li>Online kurz angličtiny LIVE, úroveň středně pokročilý B2</li>
            </ul>
            <p>
              Aplikace obsahuje systematický výukový plán na základě vašich
              cílů. Po přihlášení vás v placené verzi čeká online výuka slovíček
              rozdělená podle témat (každá úroveň obsahuje 17 běžných témat ke
              konverzaci jako setkání, rodina, charakterové vlastnosti, barvy,
              části těla, bydlení, sport, zdraví aj., seznam témat najdete{' '}
              <a
                className="font-medium text-p no-underline"
                target="_blank"
                href="/docs/prehled_slovicek_A1.pdf"
              >
                zde
              </a>
              ). Výuková aplikace je koncipována tak, že obsahuje 4 on-line
              kurzy od úrovně úplný začátečník A1 až po úroveň středně pokročilý
              B2. Každá úroveň obsahuje určitý set slovíček běžných témat, a je
              tedy jen na vás, kterým slovíčkem začnete.
            </p>
            <p>
              Aplikace také obsahuje doplňkové materiály a materiály ke stažení.
              Po shlédnutí a naučení se slovíček poskytujeme formu výuky pomocí
              čtení příběhů podle známých filmů nebo knih. Znáte-li děj v
              češtině, potom jednoduše odvodíte, co se v příběhu odehrává (v
              aplikaci najdete známé příběhy jako Popelka, Romeo a Julie, Kráska
              a zvíře, Robin Hood, Robinson Crusoe, Dobrodružství Toma Sawyera
              apod.). Anglická slovíčka si pak dokážete rychle domyslet a tím si
              je zapamatujete. Dalšími doplňkovými materiály jsou přehled
              anglické gramatiky a přehled nepravidelných sloves v minulém čase.
            </p>
            <ul role="list">
              <li>
                Po zaplacení budete mít přístup k online programu NATRVALO
              </li>
              <li>
                Tato metoda je vhodná obzvláště pro dospělé, kteří se potřebují
                rychle naučit anglicky
              </li>
              <li>
                Učení vás bude bavit a motivovat, protože uvidíte, že to funguje
              </li>
            </ul>
            <p>
              Tato metoda je jedinečná v tom, že ke každému slovíčku je
              vytvořený krátký příběh spolu s názornou ukázkou, protože obrázky
              se nám do paměti ukládají mnohem rychleji, než slova. Naše paměť
              na ně zkrátka lépe reaguje. Video vyjadřuje určité emoce a náš
              mozek si obrazy potom lépe pamatuje.
            </p>

            {/* <NavLink
              to="/app/koupit"
              className="w-full sm:w-auto mt-6 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-p hover:bg-pd no-underline"
            >
              Koupit
            </NavLink> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Courses
