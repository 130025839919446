import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Outlet } from 'react-router'
import Layout from './components/Layout'
import Home from './components/Home'
import About from './components/About'
import Courses from './components/Courses'
import Contact from './components/Contact'
import NotFound404 from './components/NotFound404'
import ScrollToTop from './components/ScrollToTop'
import Login from './components/Login'
import Join from './components/Join'
import Forgot from './components/Forgot'
import Reset from './components/Reset'
import MainApp from './components/app/Main'
import Logout from './components/app/Logout'
import NavBar from './components/Navigation'
import AppNavBar from './components/app/Navigation'
import Lessons from './components/app/Lessons'
import Lesson from './components/app/Lesson'
import Buy from './components/app/Buy'
import Downloads from './components/app/Downloads'
import Thanks from './components/app/Thanks'

const NotAuthenticated = () => {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  )
}

const Authenticated = () => {
  return (
    <>
      <AppNavBar />
      <Outlet />
    </>
  )
}

function App() {
  return (
    <Layout>
      <ScrollToTop />
      <Routes>
        <Route element={<NotAuthenticated />}>
          <Route path="/" element={<Home />} />
          <Route path="/o-nas" element={<About />} />
          <Route path="/kurzy" element={<Courses />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/prihlaseni" element={<Login />} />
          <Route path="/registrace" element={<Join />} />
          <Route path="/zapomenute-heslo" element={<Forgot />} />
          <Route path="/nove-heslo" element={<Reset />} />
          <Route path="*" element={<NotFound404 />} />
        </Route>
        <Route element={<Authenticated />}>
          <Route path="/app" element={<MainApp />} />
          <Route path="/app/odhlaseni" element={<Logout />} />
          <Route path="/app/temata" element={<MainApp page={<Lessons type="theme" />} />} />
          <Route path="/app/koupit" element={<MainApp page={<Buy />} />} />
          <Route path="/app/ke-stazeni" element={<MainApp page={<Downloads />} />} />
          <Route path="/app/dekujeme" element={<MainApp page={<Thanks />} />} />
          <Route path="/app/lekce/:number" element={<MainApp page={<Lesson />} />} />
          <Route path="/app/*" element={<NotFound404 />} />
        </Route>
      </Routes>
    </Layout>
  )
}

export default App
