import React, { useState } from 'react'
import axios from 'axios'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid'
import { useSearchParams } from 'react-router-dom'

const Errors = () => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Chybně zadaná hesla, nebo čas vypršel
          </h3>
        </div>
      </div>
    </div>
  )
} 

const Success = () => {
  return (
    <div className="rounded-md bg-pxl p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-p" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-p">
            Nové heslo nastaveno
          </h3>
        </div>
      </div>
    </div>
  )
} 

const Reset = () => {
  const [searchParams] = useSearchParams()

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const reset = () => {
    setIsError(false)
    setIsSuccess(false)
    
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/reset-password`, {
        code: searchParams.get('code'),
        password: password,
        passwordConfirmation: passwordConfirmation,
      })
      .then((response) => {
        setIsError(false)
        setIsSuccess(true)
      })
      .catch((error) => {
        setIsError(true)
        console.log('An error occurred:', error.response)
      })
  }

  return (
    <div className="">
      <header>
        <div className="max-w-7xl mx-auto px-4 pt-8 pb-4 sm:px-6 sm:pt-12">
          <h1 className="text-3xl font-bold leading-tight text-gray-900 sm:text-center">
            Nastavit nové heslo
          </h1>
        </div>
      </header>
      <main className="px-4">
        <div className="sm:max-w-md mx-auto mb-4 sm:px-6 bg-white rounded-lg shadow">
          <div className="px-4 py-8 sm:px-0">
            <form className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-6">
                {isError ? <Errors /> : ''}
                {isSuccess ? <Success /> : ''}

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nové heslo
                  </label>
                  <div className="mt-1">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="shadow-sm focus:ring-p focus:border-p block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="passwordConfirmation"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Potvrzení nového hesla
                  </label>
                  <div className="mt-1">
                    <input
                      type="password"
                      name="passwordConfirmation"
                      id="passwordConfirmation"
                      value={passwordConfirmation}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                      className="shadow-sm focus:ring-p focus:border-p block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>

              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-p hover:bg-pd"
                    onClick={(e) => {
                      e.preventDefault()
                      reset()
                    }}
                  >
                    Nastavit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Reset
