import { Fragment } from 'react'
import { Popover, Menu, Transition } from '@headlessui/react'
import {
  CogIcon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  Squares2X2Icon,
  XMarkIcon,
  UserCircleIcon,
  FolderArrowDownIcon,
  ShoppingBagIcon,
  BookOpenIcon,
  ListBulletIcon,
} from '@heroicons/react/24/outline'
import { NavLink, useLocation } from 'react-router-dom'
import { ReactComponent as Logo } from "../../logo.svg"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const navLinks = [
  {
    name: 'Témata',
    link: '/app/temata',
    icon: ListBulletIcon,
  },
  {
    name: 'Koupit',
    link: '/app/koupit',
    icon: ShoppingBagIcon,
  },
  {
    name: 'Ke stažení',
    link: '/app/ke-stazeni',
    icon: FolderArrowDownIcon,
  },
]

const userLinks = [
  // {
  //   name: 'Nastavení',
  //   link: '/app/nastaveni',
  //   icon: CogIcon,
  // },
  {
    name: 'Odhlášení',
    link: '/app/odhlaseni',
    icon: ArrowLeftOnRectangleIcon,
  },
]

const Navigation = () => {
  const location = useLocation()

  return (
    <Popover className="sticky top-0 z-50 bg-p shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        {/* <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10"> */}
        <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <NavLink to="/">
              <Logo className="h-8 w-auto sm:h-10 text-gray-100 hover:text-white" />
            </NavLink>
          </div>
          <div className="md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <nav className="hidden md:flex space-x-4">
            {navLinks.map((item) => (
              <NavLink
                key={item.name}
                to={item.link}
                className={classNames(
                  location.pathname === item.link ? 'bg-pd' : '',
                  'px-4 py-2 text-base font-medium text-white hover:bg-pl rounded-md'
                )}
              >
                {item.name}
              </NavLink>
            ))}
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            {/* Profile dropdown */}
            <Menu as="div" className="relative flex-shrink-0 ml-4">
              <div>
                <Menu.Button className="flex">
                  <UserCircleIcon className="h-8 w-auto sm:h-10 text-gray-100 hover:text-white" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="p-1">
                    {userLinks.map((link) => (
                      <Menu.Item key={link.name}>
                        {({ active }) => (
                          <NavLink
                            className={classNames(
                              active ? 'bg-pxl' : '',
                              'block w-full rounded-md text-center px-2 py-2 text-sm text-gray-500'
                            )}
                            to={link.link}
                          >
                            {link.name}
                          </NavLink>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <NavLink to="/">
                  <Logo className="h-5 w-auto sm:h-10 text-p hover:text-pd" />
                </NavLink>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-p">
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {navLinks.map((item) => (
                    <Popover.Button
                      as={NavLink}
                      key={item.name}
                      to={item.link}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-p"
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          location.pathname === item.link
                            ? 'font-bold'
                            : 'font-medium',
                          'ml-3 text-base text-gray-900'
                        )}
                      >
                        {item.name}
                      </span>
                    </Popover.Button>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="">
                <nav className="grid gap-y-8">
                  {userLinks.map((item) => (
                    <Popover.Button
                      as={NavLink}
                      key={item.name}
                      to={item.link}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-p"
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          location.pathname === item.link
                            ? 'font-bold'
                            : 'font-medium',
                          'ml-3 text-base text-gray-900'
                        )}
                      >
                        {item.name}
                      </span>
                    </Popover.Button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default Navigation
