import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const Logout = () => {
  const navigate = useNavigate()
  
  useEffect(() => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('user')
    navigate('/app/temata', { replace: true })
  }, [navigate])
  
  return
} 

export default Logout
