import { Container } from 'components/home/Container'
import troszok from 'images/avatars/troszok.jpg'
import pospisilova from 'images/avatars/pospisilova.jpg'
import rodzik from 'images/avatars/rodzik.jpg'
import benes from 'images/avatars/benes.jpg'
import niemcova from 'images/avatars/niemcova.jpg'
import veliskova from 'images/avatars/veliskova.jpg'

const testimonials = [
  [
    {
      content:
        'Vždycky jsem měla problém s výslovností anglických slovíček. Potřebuji slyšet každé slovíčko několikrát, takže tento kurz mi vyhovuje hlavně proto, že si slovíčka můžu pouštět znovu a znovu, tak dlouho až se ho naučím. Práce s programem je opravdu jednoduchá, nemusela jsem nic stahovat ani instalovat.',
      author: {
        name: 'Marcela Velíšková, Třinec',
        role: 'Recepční',
        image: veliskova,
      },
    },
    {
      content:
        'Tato paměťová technika vede k celkovému zlepšení paměti, je to přirozená cesta učení se anglických slovíček. Také mi vyhovuje, že můžu jet svým vlastním tempem a že je to konečně něco, co mě začlo bavit 😊 Škoda, že se to neučí ve škole.',
      author: {
        name: 'Anna Niemcová, Třinec',
        role: 'Prodavačka',
        image: niemcova,
      },
    },
  ],
  [
    {
      content:
        'Konečně se nemusím přemlouvat k učení. O této nové metodě slyším poprvé a zjistil jsem, že to opravdu funguje. Po výuce slovíček v kurzu pro úroveň A1 a A2 dokážu porozumět okolo 50% anglického textu a zvládnu jednoduše komunikovat.',
      author: {
        name: 'Jiří Troszok, Bystřice',
        role: 'Výroba žáruvzdorných materiálů',
        image: troszok,
      },
    },
    {
      content:
        'Slovní zásobu on-line kurzu jsem zvládnul za 2 měsíce, a hned jsem měl náskok před ostatními v Jazykové škole. 680 slovíček jsem se naučil za cenu několika lekcí v kurzu angličtiny. Používám i pracovní sešit, vyplňuji si cvičení a čtu zjednodušenou četbu.',
      author: {
        name: 'Jaroslav Rodzík, Třinec',
        role: 'Stavební dělník',
        image: rodzik,
      },
    },
  ],
  [
    {
      content:
        'Tato metoda učení se slovíček používá mnemotechnické pomůcky. Je to právě o té správné technice učení, takto se může naučit anglicky úplně každý, i děti nebo důchodci. Je to skvělý nápad převést slovíčko na příběh, to by mě nenapadlo. Tento kurz mi pomohl obnovit moje nadšení, znovu jsem se zamilovala do angličtiny. 😊',
      author: {
        name: 'Diana Pospíšilová, Hradec Králové',
        role: 'Učitelka na ZŠ',
        image: pospisilova,
      },
    },
    {
      content:
        'Abych porozuměl anglicky není potřeba znát detaily gramatiky, klíčem je naučit se nejčastěji používané slovíčka, ty si postupně automatizovat v paměti a snažit se je používat. Tento kurz mi přijde opravdu geniální nápad, kdy se každé slovíčko učím pomocí příběhu.',
      author: {
        name: 'Petr Beneš, Bukovec',
        role: 'Vedoucí projektů privátních bazénů',
        image: benes,
      },
    },
  ],
]

function QuoteIcon(props) {
  return (
    <svg aria-hidden="true" width={105} height={78} {...props}>
      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
    </svg>
  )
}

export function Testimonials() {
  return (
    <section
      id="testimonials"
      aria-label="What our customers are saying"
      className="bg-slate-50 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
            Oblíbeno lidmi po celém Česku.
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Naše metoda funguje tak skvěle, že si ji lidé nemohou nezamilovat.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 xl:mt-20 xl:max-w-none xl:grid-cols-3"
        >
          {testimonials.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8">
                {column.map((testimonial, testimonialIndex) => (
                  <li key={testimonialIndex}>
                    <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                      <QuoteIcon className="absolute left-6 top-6 fill-slate-100" />
                      <blockquote className="relative">
                        <p className="text-lg tracking-tight text-slate-900">
                          {testimonial.content}
                        </p>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                        <div>
                          <div className="font-display text-base text-slate-900">
                            {testimonial.author.name}
                          </div>
                          <div className="mt-1 text-sm text-slate-500">
                            {testimonial.author.role}
                          </div>
                        </div>
                        <div className="overflow-hidden rounded-full bg-slate-50">
                          <img
                            className="h-14 w-14 object-cover"
                            src={testimonial.author.image}
                            alt=""
                            width={56}
                            height={56}
                          />
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
