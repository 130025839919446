import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
  HomeIcon,
  UsersIcon,
  ListBulletIcon,
  EnvelopeIcon
} from '@heroicons/react/24/outline'
import { NavLink, useLocation } from "react-router-dom"
import { ReactComponent as Logo } from "../logo.svg"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const navLinks = [
  {
    name: 'Domů',
    link: '/',
    icon: HomeIcon,
  },
  {
    name: 'O nás',
    link: '/o-nas',
    icon: UsersIcon,
  },
  {
    name: 'Kurzy',
    link: '/kurzy',
    icon: ListBulletIcon,
  },
  {
    name: 'Kontakt',
    link: '/kontakt',
    icon: EnvelopeIcon,
  },
]

const Navigation = () => {
  const location = useLocation()

  return (
    <Popover className="sticky top-0 z-50 bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        {/* <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10"> */}
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <NavLink to="/">
              <Logo className="h-8 w-auto sm:h-10 text-p hover:text-pd" />
            </NavLink>
          </div>
          <div className="md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-p">
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <nav className="hidden md:flex space-x-10">
            {navLinks.map((item) => (
              <NavLink
                key={item.name}
                to={item.link}
                className={classNames(
                  location.pathname === item.link
                    ? "text-gray-900"
                    : "text-gray-500",
                  "text-base font-medium hover:text-gray-900"
                )}
              >
                {item.name}
              </NavLink>
            ))}
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <NavLink
              to="/app/temata"
              className={classNames(
                location.pathname === "/prihlaseni"
                  ? "text-gray-900"
                  : "text-gray-500",
                "whitespace-nowrap text-base font-medium hover:text-gray-900"
              )}
            >
              Přihlásit
            </NavLink>
            <NavLink
              to="/registrace"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-p hover:bg-pd"
            >
              Registrovat
            </NavLink>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <NavLink to="/">
                  <Logo className="h-5 w-auto sm:h-10 text-p hover:text-pd" />
                </NavLink>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-p">
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {navLinks.map((item) => (
                    <Popover.Button
                      as={NavLink}
                      key={item.name}
                      to={item.link}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-p"
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          location.pathname === item.link
                            ? "font-bold"
                            : "font-medium",
                          "ml-3 text-base text-gray-900"
                        )}
                      >
                        {item.name}
                      </span>
                    </Popover.Button>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                <Popover.Button
                  as={NavLink}
                  to="/registrace"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-p hover:bg-pd"
                >
                  Registrovat
                </Popover.Button>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Máte už účet?{" "}
                  <Popover.Button
                    as={NavLink}
                    to="/app/temata"
                    className="text-p hover:text-pd"
                  >
                    Přihlásit
                  </Popover.Button>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default Navigation
