import { Button } from 'components/home/Button'
import { Container } from 'components/home/Container'
// import backgroundImage from 'images/background-call-to-action.jpg'
import devices from 'images/devices.png'

import { NavLink } from 'react-router-dom'

export function CallToAction() {
  return (
    <section
      id="get-started-today"
      // className="relative overflow-hidden bg-blue-600 py-32"
      className="relative overflow-hidden bg-gray-900 py-24"
    >
      {/* <img
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        // width={2347}
        // height={1244}
      /> */}
      <Container className="relative">
        <div className="mx-auto flex max-w-5xl flex-col items-center justify-between gap-16 lg:flex-row">
          <div className="text-center">
            <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
              Jste připraveni začít?
            </h2>
            <p className="mt-4 text-lg tracking-tight text-white">
              Vytvořte si účet.
            </p>
            <NavLink
              to="/registrace"
              className="mt-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 "
            >
              Vyzkoušet zdarma
            </NavLink>
          </div>
          <div>
            <img src={devices} className="ml-8 max-w-xs sm:max-w-md lg:ml-0" />
          </div>
        </div>
      </Container>
    </section>
  )
}
