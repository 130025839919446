import { Dialog, Transition } from '@headlessui/react'
import { Button } from 'components/home/Button'
import { Container } from 'components/home/Container'
import logo_berndorf from 'images/logos/logo_berndorf.png'
import logo_donghee from 'images/logos/logo_donghee.png'
import logo_dongwon from 'images/logos/logo_dongwon.png'
import logo_ms from 'images/logos/logo_ms.png'
import logo_tp from 'images/logos/logo_tp.png'
import logo_tz from 'images/logos/logo_tz.png'
import { useState, Fragment } from 'react'
import ReactPlayer from 'react-player'

import { NavLink } from 'react-router-dom'

const VideoModal = ({ open, setOpen }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className='aspect-[16/9]'>
                  <ReactPlayer
                    url="https://youtu.be/ExFDj2unNBQ"
                    playing
                    controls
                    width='100%'
                    height='100%'
                    className="react-player-example"
                  />
                </div>
                <div className="mt-5 sm:mt-6 text-center">
                  <button
                    type="button"
                    className="w-full sm:w-32 mx-auto whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-p hover:bg-pd"
                    onClick={() => setOpen(false)}
                  >
                    Zpět
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export function Hero() {
  const [open, setOpen] = useState(false)
  
  return (
    <Container className="pb-16 pt-20 text-center lg:pt-32">
      <h1 className="font-display mx-auto max-w-5xl text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
        Nauč se{' '}
        <span className="relative whitespace-nowrap text-p">
          <svg
            aria-hidden="true"
            viewBox="0 0 418 42"
            className="absolute left-0 top-2/3 h-[0.58em] w-full fill-pl/25"
            preserveAspectRatio="none"
          >
            <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
          </svg>
          <span className="relative">anglicky</span>
        </span>{' '}
        metodou <span className="text-p">slovíčka v příběhu</span>
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
        Online program pro začátečníky, středně pokročilé i pokročilé
      </p>
      <div className="mt-8 flex flex-col sm:flex-row gap-4 justify-center">
        <NavLink
          to="/registrace"
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-p px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-pd"
        >
          Vyzkoušet zdarma
        </NavLink>
        {/* <a
          target='_blank'
          href="https://youtu.be/ExFDj2unNBQ"
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 "
        >
          <svg
            aria-hidden="true"
            className="h-3 w-3 flex-none fill-p group-active:fill-current"
          >
            <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
          </svg>
          <span className="ml-3">Sleduj video</span>
        </a> */}
        <button
          onClick={() => setOpen(true)}
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 "
        >
          <svg
            aria-hidden="true"
            className="h-3 w-3 flex-none fill-p group-active:fill-current"
          >
            <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
          </svg>
          <span className="ml-3">Sleduj video</span>
        </button>
        <VideoModal open={open} setOpen={setOpen} />
      </div>
      <div className="mt-36 lg:mt-44">
        <p className="font-display text-base text-slate-900">
          Úzce spolupracujeme s:
        </p>
        <ul
          role="list"
          className="mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0"
        >
          {[
            [
              { name: 'Berndorf', logo: logo_berndorf },
              { name: 'Donghee', logo: logo_donghee },
              { name: 'Dongwon', logo: logo_dongwon },
            ],
            [
              { name: 'Moravia Steel', logo: logo_ms },
              { name: 'Třinecká Projekce', logo: logo_tp },
              { name: 'Třinecké Železárny', logo: logo_tz },
            ],
          ].map((group, groupIndex) => (
            <li key={groupIndex}>
              <ul
                role="list"
                className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0"
              >
                {group.map((company) => (
                  <li key={company.name} className="flex">
                    <img className='min-w-[4rem] h-8 grayscale' src={company.logo} alt={company.name} />
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  )
}
