import React from 'react'
import Footer from './Footer'

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* <Navigation /> */}
      {children}
      <Footer />
    </div>
  )
}

export default Layout
