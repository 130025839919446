import { Container } from 'components/home/Container'
import backgroundImage from 'images/background-faqs.jpg'

const faqs = [
  [
    {
      question: 'Jak probíhá přihlašování?',
      answer:
        'Na našich webových stránkách www.slovickalive.cz najdete vpravo nahoře PŘIHLÁSIT nebo REGISTROVAT. V případě, že ještě nemáte založený uživatelský účet, klikněte na REGISTROVAT. Vyplňte požadované údaje a potom klikněte na PŘIHLÁSIT. Po přihlášení vás záložka KOUPIT dovede k procesu nákupu a vyřízení platby. Online program můžete používat po zaplacení. V placené části můžete plně využít online výuku slovíček pro danou úroveň.',
    },
    {
      question: 'Kolik stojí celý kurz?',
      answer: 'Každá úroveň online kurzu stojí 679 Kč. Kurz obsahuje 4 úrovně angličtiny.',
    },
  ],
  [
    {
      question: 'Co je to mnemotechnika?',
      answer:
        'Mnemotechnika je metoda, která slouží k jednoduššímu zapamatování si většího množství informací, obzvláště díky vytváření si pomocných asociací a představ. V našem online programu je tato metoda jedinečná v tom, že jsme ke každému slovíčku vytvořili krátký příběh spolu s názornou ukázkou, protože obrázky se nám do paměti ukládají mnohem rychleji, než slova. Naše paměť na ně zkrátka lépe reaguje.',
    },
    {
      question:
        'Jak dlouho budu mít přístup k online programu?',
      answer:
        'Po zaplacení budete mít přístup k online programu NATRVALO',
    },
  ],
  [
    {
      question: 'Co obsahuje každá úroveň?',
      answer:
        <span>Každá úroveň obsahuje určitý set slovíček běžných témat, a je jen na vás, kterým slovíčkem začnete. Po přihlášení vás v placené části čeká výuka slovíček rozdělená podle témat (každá úroveň obsahuje 17 běžných témat ke konverzaci jako setkání, rodina, charakterové vlastnosti, barvy, části těla, bydlení, sport, zdraví aj., seznam témat najdete <a target='_blank' href="/docs/prehled_slovicek_A1.pdf" className='font-medium text-p hover:text-pd'>zde</a>).</span>,
    },
    {
      question: 'Jak poznám, která úroveň je pro mě vhodná?',
      answer:
        <span>Podle společného evropského referenčního rámce pro jazyky rozdělujeme úroveň angličtiny do 6 základních skupin (A1, A2, B1, B2, C1, C2). Více informací o rozdělení úrovní si přečtěte na <a target='_blank' href="https://livetrinec.cz/nase-kurzy/" className='font-medium text-p hover:text-pd'>livetrinec.cz/nase-kurzy</a></span>,
    },
  ]
]

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Nejčastější dotazy (FAQ)
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Pokud nemůžete najít, co hledáte, pošlete e-mail našemu týmu podpory a my se vám ozveme zpět.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
