import { useId } from 'react'
import clsx from 'clsx'

import { Container } from 'components/home/Container'

const features = [
  {
    name: '680 slovíček on-line',
    descriptions: [
      'Anglické slovíčko je nahrazeno českým výrazem, které se nám automaticky ukládá do paměti',
      'Nová, ověřená metoda, která konečně funguje',
      'Slovíčka jsou rozdělena podle témat pro úroveň A1, A2, B1, B2',
      'Kurz obsahuje také pracovní sešit s gramatikou dané úrovně a zjednodušenou četbu známých příběhů, jako je Popelka, Romeo a Julie, Robin Hood a další',
      'Každý kurz se dá zvládnout za 2 měsíce',
      'Cena kurzu je 679 Kč, zaplatíte tedy 1 Kč za 1 slovíčko',
    ],
    icon: function ReportingIcon() {
      let id = useId()
      return (
        <>
          <defs>
            <linearGradient
              id={id}
              x1="11.5"
              y1={18}
              x2={36}
              y2="15.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset=".194" stopColor="#fff" />
              <stop offset={1} stopColor="#6692F1" />
            </linearGradient>
          </defs>
          <path
            d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5"
            stroke={`url(#${id})`}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    },
  },
  {
    name: 'Metoda klíčového slova ve videu',
    descriptions: [
      'Slovíčka s mnemotechnickou pomůckou se učíme pomocí příběhu',
      'U každého slovíčka je ukázková věta s jeho konkrétním příkladem',
      'Po každé sadě 5 slovíček náš systém díky jednoduchého cvičení prověří, jestli jste se slovíčka opravdu naučili',
      'Tato metoda vede ke zlepšení paměti',
      'U každého slovíčka uslyšíte i jeho výslovnost, můžete si ho pouštět podle potřeby znovu a znovu',
    ],
    icon: function InventoryIcon() {
      return (
        <>
          <path
            opacity=".5"
            d="M8 17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
          <path
            opacity=".3"
            d="M8 24a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
          <path
            d="M8 10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
        </>
      )
    },
  },
  {
    name: 'Nejrychlejší cesta k anglickým slovíčkům',
    descriptions: [
      'Jednoduchá a zábavná forma výuky',
      'Slovíčka zůstanou v paměti už navždy',
      'Je důležité si uvědomit, jak naše paměť a mozek funguje.',
      'Díky systematického výukového plánu dojdete k dosažení svých cílů',
      'S námi se dostanete na takovou úroveň, která pro Vás bude přirozená',
      'Online kurz můžete použít na jakémkoli zařízení - na PC, notebooku, tabletu i telefonu',
    ],
    icon: function ContactsIcon() {
      return (
        <>
          <path
            opacity=".5"
            d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z"
            fill="#fff"
          />
          <path
            d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z"
            fill="#fff"
          />
        </>
      )
    },
  },
]

function Feature({ feature, className, ...props }) {
  return (
    <div className={clsx(className)} {...props}>
      <div className="w-9 rounded-lg bg-p">
        <svg aria-hidden="true" className="h-9 w-9" fill="none">
          <feature.icon />
        </svg>
      </div>
      <h3 className="mt-6 text-lg font-medium text-p">{feature.name}</h3>
      <ul className="mt-4 list-inside list-disc text-sm text-gray-600">
        {feature.descriptions.map((description, i) => (
          <li key={i}>{description}</li>
        ))}
      </ul>
    </div>
  )
}

function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.name}>
          <Feature feature={feature} className="mx-auto max-w-2xl" />
        </div>
      ))}
    </div>
  )
}

function FeaturesDesktop() {
  return (
    <div className="hidden lg:mt-20 lg:block">
      <div className="grid grid-cols-3 gap-x-8">
        {features.map((feature) => (
          <Feature
            key={feature.name}
            feature={{
              ...feature,
              name: (
                <div className="ui-not-focus-visible:outline-none">
                  <span className="absolute inset-0" />
                  {feature.name}
                </div>
              ),
            }}
            className="relative"
          />
        ))}
      </div>
    </div>
  )
}

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for simplifying everyday business tasks"
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-gray-900 sm:text-4xl">
            Tip pro začatečníky!
          </h2>
          <p className="mt-4 text-lg tracking-tight text-gray-700">
            Prvním krokem je vybudovat si slovní zásobu od základů. Naučte se
            každý den alespoň 5 slovíček, slovní zásobu si tak rozšíříte za
            měsíc o téměř 150 slovíček. Pro začátečníky doporučujeme nejdříve
            osvojit si základy angličtiny na úrovni A1 a postupně se učit
            slovíčka na vyšší úrovni. Učte se pro potěšení díky této zábavné
            formě výuky.
          </p>
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </Container>
    </section>
  )
}
