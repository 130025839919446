import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid'
import { useQuery, gql } from '@apollo/client'
import { lookupIcon } from 'heroicons-lookup'
import { useQueryState } from 'react-router-use-location-state'
import { Link } from 'react-router-dom'
import { DebounceInput } from 'react-debounce-input'
import Spinner from './Spinner'

const LESSONS = gql`
  query filteredLessons(
    $type: String!
    $search: String!
    $difficulty: String!
    $completed: String!
  ) {
    filteredLessons(
      type: $type
      search: $search
      difficulty: $difficulty
      completed: $completed
    ) {
      id
      number
      name
      type
      difficulty
      icon
      thumbnail {
        data {
          attributes {
            name
            url
            previewUrl
          }
        }
      }
      completed
    }
  }
`

const lessonTypes = {
  theme: { header: 'Témata', description: 'Lekce podle témat s filtrováním' },
}

const diffOptions = [
  { key: 'free', text: 'zdarma' },
  { key: 'A1', text: 'A1' },
  // { key: 'A2', text: 'A2' },
  // { key: 'B1', text: 'B1' },
  // { key: 'B2', text: 'B2' },
]

const completedOptions = [
  { key: 'all', text: 'všechny' },
  { key: 'completed', text: 'dokončené' },
  { key: 'uncompleted', text: 'nedokončené' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const HeroIcon = ({ iconName }) => {
  const clearAndUpper = (text) => text.replace(/-/, '').toUpperCase()
  const toPascalCase = (text) => text.replace(/(^\w|-\w)/g, clearAndUpper)
  let Icon
  try {
    Icon = lookupIcon(toPascalCase(iconName + 'Icon'), 'outline')
  } catch (e) {
    Icon = lookupIcon(toPascalCase('BookOpenIcon'), 'outline')
  }
  return (
    <Icon className="h-full w-full stroke-1 object-cover object-center p-8" />
  )
}

const LessonsGrid = ({ type, search, difficulty, completed }) => {
  const { loading, error, data } = useQuery(LESSONS, {
    variables: { type, search, difficulty, completed },
    fetchPolicy: 'network-only',
  })

  if (error)
    return (
      <div className="flex h-full items-center justify-center px-4">
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {error && error.message === 'DIFFICULTY_NOT_PAID'
                  ? 'Úroveň nezaplacena'
                  : error && error.message === 'NO_LESSONS_FOUND'
                    ? 'Úroveň nemá žádné lekce'
                    : 'Chyba'}
              </h3>
            </div>
          </div>
        </div>
      </div>
    )
  if (loading)
    return (
      <div className="flex h-full items-center justify-center px-4">
        <Spinner />
      </div>
    )
  if (data && data.filteredLessons.length)
    return (
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 hd:grid-cols-6">
        {data.filteredLessons.map((lesson) => (
          <Link
            to={`/app/lekce/${lesson.number}`}
            key={lesson.number}
            className="group relative cursor-pointer rounded-lg p-2 hover:bg-pxl"
          >
            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 text-gray-500 group-hover:bg-p group-hover:text-white">
              {lesson.thumbnail.data ? (
                <img
                  src={lesson.thumbnail.data.attributes.url}
                  className="scale-105 object-cover brightness-75 transition duration-300 group-hover:scale-[120%] group-hover:brightness-100"
                />
              ) : (
                <HeroIcon
                  iconName={lesson.icon ? lesson.icon : 'book-open'}
                  className="h-full w-full object-cover object-center"
                />
              )}
              {lesson.completed ? (
                <CheckCircleIcon className="absolute ml-auto mr-2 mt-2 h-8 w-8 rounded-full bg-white text-p" />
              ) : null}
            </div>
            <div className="pb-4 pt-10 text-center">
              <h3 className="text-sm font-medium text-gray-700">
                {`${lesson.number}. lekce`}
              </h3>
              <p className="mt-2 text-base font-medium text-gray-900">
                {lesson.name}
              </p>
              <p className="mt-2 text-base font-medium text-gray-900">
                {diffOptions.find((o) => o.key === lesson.difficulty).text}
              </p>
            </div>
          </Link>
        ))}
      </div>
    )
}

const Lessons = ({ type }) => {
  const [search, setSearch] = useQueryState('search', '')
  const [difficulty, setDifficulty] = useQueryState('difficulty', 'free')
  const [completed, setCompleted] = useQueryState('completed', 'all')

  return (
    <>
      <section className="px-4 py-16 text-center sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-gray-900">
          {lessonTypes[type].header}
        </h1>
        <p className="mx-auto mt-4 max-w-xl text-base text-gray-500">
          {lessonTypes[type].description}
        </p>
      </section>

      {/* Filters */}
      <section className="z-20 mx-2 border-t border-t-gray-200 pb-4 pt-6">
        <div className="mx-auto flex max-w-7xl flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
          <div className="group relative mr-auto inline-block w-full sm:max-w-sm">
            <DebounceInput
              autoComplete="off"
              placeholder="hledat"
              className={classNames(
                search ? 'bg-pxl' : 'bg-gray-100',
                'w-full rounded-md border-transparent px-4 py-2 text-sm font-medium text-gray-700 placeholder-gray-700 hover:text-gray-900 focus:border-p focus:bg-pxl focus:ring-p',
              )}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              debounceTimeout={500}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
              <MagnifyingGlassIcon
                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </div>
          </div>

          <Menu
            as="div"
            className="relative ml-auto inline-block w-full sm:w-auto"
          >
            <Menu.Button
              className={classNames(
                completed === 'all' ? 'bg-gray-100' : 'bg-pxl',
                'group inline-flex w-full justify-between rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900',
              )}
            >
              {completedOptions.find((o) => o.key === completed).text}
              <ChevronDownIcon
                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-20 mt-2 w-full origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:w-40">
                <div className="p-1">
                  {completedOptions.map((option) => (
                    <Menu.Item key={option.key}>
                      {({ active }) => (
                        <button
                          className={classNames(
                            option.key === completed
                              ? 'font-medium text-gray-900'
                              : 'text-gray-500',
                            active ? 'bg-pxl' : '',
                            'w-full rounded-md px-2 py-2 text-sm',
                          )}
                          onClick={() => setCompleted(option.key)}
                        >
                          {option.text}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <Menu
            as="div"
            className="relative ml-auto inline-block w-full sm:w-auto"
          >
            <Menu.Button
              className={classNames(
                difficulty === 'all' ? '' : 'bg-pxl',
                'group inline-flex w-full justify-between rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900',
              )}
            >
              {diffOptions.find((o) => o.key === difficulty).text}
              <ChevronDownIcon
                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-20 mt-2 w-full origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:w-20">
                <div className="p-1">
                  {diffOptions.map((option) => (
                    <Menu.Item key={option.key}>
                      {({ active }) => (
                        <button
                          className={classNames(
                            option.key === difficulty
                              ? 'font-medium text-gray-900'
                              : 'text-gray-500',
                            active ? 'bg-pxl' : '',
                            'w-full rounded-md px-2 py-2 text-sm',
                          )}
                          onClick={() => setDifficulty(option.key)}
                        >
                          {option.text}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </section>

      {/* Lessons grid */}
      <section className="min-h-[18rem]">
        <LessonsGrid
          type={type}
          search={search}
          difficulty={difficulty}
          completed={completed}
        />
      </section>
    </>
  )
}

export default Lessons
