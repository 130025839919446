import { useQuery, useMutation, gql } from '@apollo/client'
import { XMarkIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactPlayer from 'react-player'
import * as _ from 'lodash'
import Spinner from './Spinner'

const SPLIT = 5 // po kolika slovech rozdelit lekci, musi byt vetsi nez MIN_WORDS
const MIN_WORDS = 3 // minimalni pocet slov v poslednim rozdeleni lekce

const LESSON = gql`
  query paidLesson($number: Int!) {
    paidLesson(number: $number) {
      id
      number
      name
      type
      difficulty
      icon
      paidWords {
        englishWord
        pronunciation
        czechWord
        sentence
        video {
          data {
            attributes {
              name
              url
            }
          }
        }
      }
    }
  }
`
const COMPLETE_LESSON = gql`
  mutation completeLesson($number: Int!) {
    completeLesson(number: $number) 
  }
`

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Finished = () => {
  const params = useParams()

  const [ completeLessonMutation, { loading, error, data } ] = useMutation(COMPLETE_LESSON)

  useEffect(() => {
    completeLessonMutation({ variables: { number: parseInt(params.number) } })
  }, [])

  return (
    // <div className="w-full my-4 h-[360px] sm:h-[530px] lg:h-[690px]">
    <div className="w-full my-4 sm:my-16">
      <div className="flex items-center justify-center h-full">
        Lekce hotova
      </div>
    </div>
  )
}

const Video = ({ word, completeSlide, slideIdx }) => {
  const [playing, setPlaying] = useState(true)
  useEffect(() => completeSlide(slideIdx), [word])
  
  return (
    <>
      <div
        // className="-mx-4 sm:mx-0 my-4 h-[360px] sm:h-[530px] lg:h-[690px]"
        className="-mx-4 sm:mx-0 my-4 aspect-[16/9]"
        onLoad={() => this.click()}
      >
        <ReactPlayer
          url={word.video.data.attributes.url}
          loop={true}
          playing={playing}
          width="100%"
          height="100%"
          // config={{
          //   file: {
          //     attributes: {
          //       style: { width: '100%', borderRadius: '0.375rem' },
          //     },
          //   },
          // }}
          className="react-player-lesson"
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          onContextMenu={((e) => e.preventDefault())}
          onClick={() => setPlaying(prev => !prev)}
        />
      </div>
    </>
  )
}

const Test = ({ words, completeSlide, slideIdx }) => {
  const [left, setLeft] = useState(null)
  const [right, setRight] = useState(null)
  const [wrongLeft, setWrongLeft] = useState(null)
  const [wrongRight, setWrongRight] = useState(null)
  const [leftArray, setLeftArray] = useState([])
  const [rightArray, setRightArray] = useState([])
  const [completed, setCompleted] = useState([])

  useEffect(() => {
    let leftArray = words.map((word, i) => ({ ...word, key: i }))
    let rightArray = words.map((word, i) => ({ ...word, key: i }))
    rightArray.sort(() => Math.random() - 0.5)

    setLeftArray(leftArray)
    setRightArray(rightArray)
  }, [words])
  
  useEffect(() => {
    if (left != null && right != null) {
      if (left === right) {
        setLeft(null)
        setRight(null)
        setWrongLeft(null)
        setWrongRight(null)
        setCompleted(prev => [...prev, left])
      }
      else {
        setWrongLeft(left)
        setWrongRight(right)
        setLeft(null)
        setRight(null)
      }
    }
  }, [left, right])

  useEffect(() => {
    if (completed.length === words.length) completeSlide(slideIdx)
  }, [completed])
  
  return (
    // <div className="w-full my-4 h-[360px] sm:h-[530px] lg:h-[690px]">
    <div className="w-full my-32 sm:my-4 aspect-[16/9]">
      <div className="flex gap-2 items-center justify-center h-full">
        <div className="flex flex-col gap-2">
          {leftArray.map((word) => (
            <button
              key={word.key}
              type="button"
              disabled={completed.includes(word.key)}
              className={classNames(
                word.key === left
                  ? 'bg-p'
                  : '',
                word.key === wrongLeft
                  ? 'bg-red-400'
                  : '',
                completed.includes(word.key)
                  ? 'text-gray-300 bg-pxl'
                  : 'text-gray-700',
                'inline-flex justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium'
              )}
              onClick={() => {
                setLeft(word.key)
                setWrongLeft(null)
                setWrongRight(null)
              }}
            >
              {word.englishWord}
            </button>
          ))}
        </div>
        <div className="flex flex-col gap-2">
          {rightArray.map((word) => (
            <button
              key={word.key}
              type="button"
              disabled={completed.includes(word.key)}
              className={classNames(
                word.key === right
                  ? 'bg-p'
                  : '',
                  word.key === wrongRight
                  ? 'bg-red-400'
                  : '',
                completed.includes(word.key)
                  ? 'text-gray-300 bg-pxl'
                  : 'text-gray-700',
                'inline-flex justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium'
              )}
              onClick={() => {
                setRight(word.key)
                setWrongLeft(null)
                setWrongRight(null)
              }}
            >
              {word.czechWord}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

const Lesson = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [index, setIndex] = useState(0)
  const [slides, setSlides] = useState([])
  
  const decrementIndex = () => setIndex(prev => (prev - 1))
  const incrementIndex = () => setIndex(prev => (prev + 1))

  const completeSlide = (index) => {
    let updated = [...slides]
    updated[index].completed = true
    setSlides(updated)
  }

  const { loading, error, data } = useQuery(LESSON, {
    variables: { number: parseInt(params.number) },
  })

  useEffect(() => {
    // rozdeleni na slidy s videi pro SPLIT pocet slov, potom test predchazejicich slov
    if (data && data.paidLesson.paidWords.length) {
      const slidesArray = []
      let words = _.shuffle(_.cloneDeep(data.paidLesson.paidWords))

      while (words.length) {
        let splice = []
        if (words.length >= SPLIT + MIN_WORDS) splice = words.splice(0, SPLIT)
        else splice = words.splice(0, words.length)

        splice.map((word) =>
          slidesArray.push({
            type: 'video',
            completed: false,
            data: word,
          })
        )
        slidesArray.push({
          type: 'test',
          completed: false,
          data: splice,
        })
      }

      slidesArray.push({ type: 'finished', completed: false })
      setSlides(slidesArray)
    }
  }, [data])

  if (error)
    return (
      <div className="px-4 flex items-center justify-center h-full">
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {error && error.message === 'DIFFICULTY_NOT_PAID'
                  ? 'Úroveň nezaplacena'
                  : error && error.message === 'LESSON_DOES_NOT_EXIST'
                  ? 'Lekce neexistuje'
                  : 'Chyba'}
              </h3>
            </div>
          </div>
        </div>
      </div>
    )
  if (loading)
    return (
      <div className="px-4 flex items-center justify-center h-full">
        <Spinner />
      </div>
    )
  if (data && slides.length)
    return (
      <>
        <section>
          <div className="pt-2 pb-4 sm:pt-0 flex items-center justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {`${data.paidLesson.number}. lekce - ${data.paidLesson.name}`}
            </h3>
            <div className="flex">
              <button
                type="button"
                className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => navigate(-1)}
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>

          <div className="">
            <div className="overflow-hidden rounded-full bg-pxl">
              <div
                className="h-1 rounded-full bg-p transition-[width] duration-500"
                style={{
                  width: `calc((${index} + 1) / ${slides.length} * 100%)`,
                }}
              />
            </div>
          </div>
        </section>

        <section>
          {index === slides.length - 1 ? (
            <Finished />
          ) : slides[index].type === 'video' ? (
            <Video
              word={slides[index].data}
              completeSlide={completeSlide}
              slideIdx={index}
            />
          ) : slides[index].type === 'test' ? (
            <Test
              words={slides[index].data}
              completeSlide={completeSlide}
              slideIdx={index}
            />
          ) : null}
        </section>

        <section className="flex justify-center mt-auto">
          <button
            type="button"
            onClick={decrementIndex}
            disabled={index < 1}
            className={classNames(
              index < 1 ? 'text-gray-300' : 'text-gray-700 hover:bg-gray-50',
              'inline-flex items-center pl-2 pr-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium bg-white'
            )}
          >
            <ChevronLeftIcon className="h-6 w-6 -ml-0.5 mr-2" />
            Zpět
          </button>
          {index + 1 < slides.length ? (
            <button
              type="button"
              onClick={incrementIndex}
              disabled={!slides[index].completed}
              className={classNames(
                !slides[index].completed
                  ? 'text-gray-300'
                  : 'text-gray-700 hover:bg-gray-50',
                'ml-4 inline-flex items-center pl-4 pr-2 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium bg-white'
              )}
            >
              Další
              <ChevronRightIcon className="h-6 w-6 ml-2 -mr-0.5" />
            </button>
          ) : (
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="ml-4 inline-flex items-center pl-4 pr-2 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium bg-white text-gray-700 hover:bg-gray-50"
            >
              Zavřít
              <XMarkIcon className="h-6 w-6 ml-2 -mr-0.5" />
            </button>
          )}
        </section>
      </>
    )
  return <div className="px-4">Lekce neobsahuje žádná slova</div>
}

export default Lesson
