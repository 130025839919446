import { useQuery, gql } from '@apollo/client'
import InnerHTML from 'dangerously-set-html-content'
import { useEffect } from 'react'
import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/20/solid'

const products = {
  A1: {
    name: 'Slovíčka Live - A1',
    initials: 'A1',
    desc: 'úplný začátečník',
    code: 361146,
  },
  // A2: {
  //   name: 'Slovíčka Live - A2',
  //   initials: 'A2',
  //   desc: 'začátečník',
  //   code: 361147,
  // },
  // B1: {
  //   name: 'Slovíčka Live - B1',
  //   initials: 'B1',
  //   desc: 'mírně pokročilý',
  //   code: 361148,
  // },
  // B2: {
  //   name: 'Slovíčka Live - B2',
  //   initials: 'B2',
  //   desc: 'středně pokročilý',
  //   code: 361149,
  // },
}

const USER = gql`
  query {
    me {
      id
      username
      email
      paidA1
      paidA2
      paidB1
      paidB2
    }
  }
`

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Product = ({ productData, purchased }) => {
  return (
    <li
      key={productData.name}
      className="col-span-1 flex border border-gray-200 rounded-md shadow-sm"
    >
      <div
        className={classNames(
          purchased ? 'bg-p text-white' : 'bg-gray-200 text-gray-900',
          'flex-shrink-0 flex items-center justify-center w-16 text-sm font-medium rounded-l-md'
        )}
      >
        {productData.initials}
      </div>
      <div className="flex flex-1 items-center justify-between truncate rounded-r-md bg-white">
        <div className="flex-1 truncate px-4 py-2 text-sm">
          <span className="font-medium text-gray-900">{productData.name}</span>
          <p className="text-gray-500">{productData.desc}</p>
        </div>
        <div className="flex-shrink-0 pr-2">
          <div
            className={classNames(
              'inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent focus:outline-none'
            )}
          >
            {purchased ? (
              <CheckCircleIcon className="text-p h-5 w-5" />
            ) : (
              <XCircleIcon className="text-gray-200 h-5 w-5" />
            )}
          </div>
        </div>
      </div>
    </li>
  )
}

const Buy = () => {
  const { loading, error, data } = useQuery(USER)

  useEffect(() => {
    if (data && data.me) {
      document.addEventListener('fapiFormMounted', (event) => {
        const formElement = event.detail.formWrapper
        const itemsCheckboxes = formElement.querySelectorAll(
          '.fapi-form-items input[type=checkbox]'
        )
        itemsCheckboxes.forEach((checkbox) => {
          checkbox.checked = false
          checkbox.dispatchEvent(new Event('change'))
        })

        if (data.me.paidA1) {
          const elem = formElement.querySelector(
            `[class*="fapi-product-${products.A1.code}"]`
          )
          if (elem) elem.remove()
        }
        // if (data.me.paidA2) {
        //   const elem = formElement.querySelector(
        //     `[class*="fapi-product-${products.A2.code}"]`
        //   )
        //   if (elem) elem.remove()
        // }
        // if (data.me.paidB1) {
        //   const elem = formElement.querySelector(
        //     `[class*="fapi-product-${products.B1.code}"]`
        //   )
        //   if (elem) elem.remove()
        // }
        // if (data.me.paidB2) {
        //   const elem = formElement.querySelector(
        //     `[class*="fapi-product-${products.B2.code}"]`
        //   )
        //   if (elem) elem.remove()
        // }

        const email = formElement.querySelector(`input[name=email]`)
        email.value = data.me.email
        email.disabled = true
        email.style = `color: #666 !important; background-color: #eee !important;`
        email.dispatchEvent(new Event('blur'))
      })
    }
  }, [data])

  return (
    <>
      <section className="text-center py-16 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-gray-900">
          Koupit
        </h1>
        <p className="mt-4 max-w-xl mx-auto text-base text-gray-500">
          Zde můžete koupit kurz pro úroveň A1, další úrovně připravujeme
        </p>
      </section>

      <section className="pt-6 pb-4 z-10 border-t border-t-gray-200 mx-2">
        <div className="max-w-xl mx-auto">
          {data && data.me ? (
            <>
              <h2 className="text-sm font-medium text-gray-500">
                Zakoupené kurzy pro {data.me.email}:
              </h2>
              <ul className="mt-3 grid grid-cols-1 gap-4">
                <Product productData={products.A1} purchased={data.me.paidA1} />
                {/* <Product productData={products.A2} purchased={data.me.paidA2} />
                <Product productData={products.B1} purchased={data.me.paidB1} />
                <Product productData={products.B2} purchased={data.me.paidB2} /> */}
              </ul>
            </>
          ) : null}
          {data && data.me.paidA1 ? (
            // &&
            // data.me.paidA2
            // &&
            // data.me.paidB1
            // &&
            // data.me.paidB2
            <>
              <CheckCircleIcon className="mt-16 mb-4 mx-auto text-p h-16 w-16" />
              <div className="text-center mb-8">
                Již vlastníte všechny úrovně Slovíčka Live
              </div>
            </>
          ) : (
            <>
              <div className="pt-16 pb-8 text-center flex items-center justify-center h-full">
                <div className="rounded-md bg-orange-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-orange-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-orange-800">
                        Před odesláním objednávky zkontrolujte objednávané
                        položky a zda souhlasí email s Vaším Slovíčka Live účtem
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <InnerHTML html='<script type="text/javascript" src="https://form.fapi.cz/script.php?id=6d122afb-9d7b-4acf-8f57-2e11def27190"></script>' />
            </>
          )}
        </div>
      </section>
    </>
  )
}

export default Buy
