const Thanks = () => {
  return (
    <div className="max-w-7xl mx-auto h-full w-full">
      <section className="text-center py-16 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-gray-900">
          Děkujeme
        </h1>
      </section>

      <section className="pt-6 pb-4 z-10 border-t border-t-gray-200 mx-2">
        <div class="mx-auto sm:mt-16 sm:text-center">
          <div className="text-lg font-medium">
            Zdravíme Vás, děkujeme Vám za projevený zájem o aplikaci Slovíčka
            Live.
          </div>
          <br />
          <div>
            Vaše objednávka byla přijata, uhrazena. Váš účet jsme nyní
            aktivovali, můžete začít s učením.
          </div>
          <br />
          <div>
            S celým týmem Vám přejeme mnoho úspěchů ve výuce slovíček. 😊
          </div>
        </div>
      </section>
    </div>
  )
}

export default Thanks
