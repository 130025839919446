import React from 'react'
import { NavLink } from 'react-router-dom'

const NotFound404 = () => {
  return (
    <main className="flex flex-grow flex-col justify-center bg-gradient-to-b from-pxl to-white">
      <div className="py-16 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="text-6xl font-semibold text-p">404</p>
          <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Stránka nenalezena.
          </h1>
          <p className="mt-2 text-base text-gray-500">
            Omlouváme se, ale stránku, kterou hledáte, jsme nenašli.
          </p>
          <div className="mt-6">
            <NavLink
              to="/"
              className="text-base font-medium text-p hover:text-pd"
            >
              Zpět domů
              <span aria-hidden="true"> &rarr;</span>
            </NavLink>
          </div>
        </div>
      </div>
    </main>
  )
}

export default NotFound404
