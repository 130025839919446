import React from 'react'

import about from 'images/about.jpg'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const About = () => {
  return (
    <div className="relative lg:grid lg:grid-cols-2 flex-1">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:fixed lg:inset-y-0 lg:left-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            src={about}
            alt=""
          />
        </div>
      </div>
      <div className="relative bg-white lg:col-start-2">
        <div className="max-w-prose text-base lg:max-w-lg pt-12 sm:pt-16 mx-auto lg:mr-auto px-4 sm:px-6 lg:px-8 pb-16 lg:ml-6">
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              O nás
            </h3>
            <p className="mt-8 text-lg text-gray-500">
              V Jazykovém centru{' '}
              <a target='_blank' href="https://livetrinec.cz" className="text-p font-medium">
                LIVE
              </a>{' '}
              chceme našim studentům předat vědomosti a schopnosti, aby je mohli
              využít kdekoli na světě (v mezinárodních společnostech, na
              dovolené v cizích zemích apod.). Spousta lidí investuje do výuky
              cizího jazyka spoustu času a energie, platí hodně peněz, ale stále
              se nemohou dostat na úroveň, která by pro ně byla přirozená. Rádi
              by si něco přečetli nebo se s někým pobavili bez stresu a
              nervozity, ale nedokážou si přeložit každé slovíčko
            </p>
            <div className="prose prose-indigo mt-5 text-gray-500">
              <p>
                Poskytujeme širokou nabídku kvalitních a odborných jazykových
                služeb. Všichni jistě víme, že dnešní doba téměř vyžaduje
                schopnost domluvit se s cizinci nebo vyřídit základní
                korespondenci v cizím jazyce.
              </p>
              <p>
                Výuce angličtiny se věnujeme 15 let a paměťovým technikám více
                než 5 let. Jazykové vzdělávání poskytujeme široké veřejnosti
                formou jazykových kurzů a nově také formou tohoto online
                programu. Klademe důraz na potřeby každého studenta. A protože
                nám záleží na kvalitě výuky, připravili jsme pro vás tuto novou
                techniku v učení se anglických slovíček. Nová metoda LIVE je
                zábavná, systematická a nejrychlejší metoda v učení se slovíček
                na světě. Funguje na technice učení spojením neznámého
                anglického slovíčka se známým výrazem v češtině, které jsme
                zakomponovali do příběhu ve formě krátkého videa. Slovíčko se
                nám potom téměř automaticky uloží do paměti. Už žádné biflování,
                ale snadno a jednoduše si zapamatujete anglické slovíčko v
                příběhu.
              </p>
              <p>
                Výuka probíhá za použití paměťových technik neboli
                mnemotechniky. Na paměťových soutěžích se lidé dokážou naučit až
                10 slovíček za minutu a je zajímavé, že nemají fotografickou
                paměť ani nejsou geniální. Jediné, na čem záleží, je si
                uvědomit, jak naše paměť a mozek funguje. Co tito lidé tedy
                ovládají, je správná technika.
              </p>
              <p>
                Naše vize je předávat principy této techniky a proměnit je v
                „živý příběh“, proto LIVE. Náš záměr je, abyste se slovíčka
                nejen naučili, ale abyste je dokázali sami říct, a domluvili se
                po celém světě bez jakýchkoli komplikací.
              </p>
            </div>
          </div>
      </div>
    </div>
  )
}

export default About
