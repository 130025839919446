import React, { useEffect } from 'react'
import {
  GlobeAltIcon,
  EnvelopeIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline'
import { InstagramEmbed, FacebookEmbed } from 'react-social-media-embed'

const Contact = () => {
  return (
    <main className="flex flex-grow bg-gradient-to-b from-pxl to-white">
      <div className="mx-auto px-4 py-16 sm:px-6 lg:px-8 hd:max-w-7xl">
        <h2 className="text-2xl font-bold text-gray-900 sm:text-center sm:text-3xl sm:tracking-tight">
          Jazykové centrum LIVE
        </h2>
        <div className="mt-3 sm:text-center">
          <p className="text-lg text-gray-500">1. Máje 276, 739 61 Třinec</p>
        </div>

        <div className="my-12 grid grid-cols-1 gap-4 sm:grid-cols-3">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <div className="flex-shrink-0">
                <PhoneIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="text-base text-gray-500">
                <p>+420 737 523 225</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="flex-shrink-0">
                <PhoneIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="text-base text-gray-500">
                <p>Po-Pá od 8:00 do 18:00</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <div className="flex-shrink-0">
                <GlobeAltIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="text-base text-gray-500">
                <a href="https://www.slovickalive.cz">www.slovickalive.cz</a>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="flex-shrink-0">
                <GlobeAltIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="text-base text-gray-500">
                <a href="https://www.livetrinec.cz">www.livetrinec.cz</a>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <div className="flex-shrink-0">
                <EnvelopeIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="text-base text-gray-500">
                <a href="mailto:info@slovickalive.cz">info@slovickalive.cz</a>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="flex-shrink-0">
                <EnvelopeIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="text-base text-gray-500">
                <a href="mailto:info@livetrinec.cz">info@livetrinec.cz</a>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="max-w-[340px] overflow-hidden">
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FJazykoveCentrumLIVE&tabs=timeline&width=340&height=640&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false"
              width="340"
              height="380"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            />
          </div>

          <div className="max-w-[340px] overflow-hidden">
          <InstagramEmbed
            url="https://www.instagram.com/slovicka_v_pribehu/"
            width="100%"
          />
          </div>
        </div>
      </div>
    </main>
  )
}

export default Contact
