import { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import ReactPlayer from 'react-player'
import { Container } from 'components/home/Container'

const features = [
  {
    title: 'Úroveň A1',
    description: <span>K zakoupení ihned za 679 Kč</span>,
    videos: [
      {
        name: 'platit',
        url: '/videos/pay.mp4',
      },
      {
        name: 'mazlíček',
        url: '/videos/pet.mp4',
      },
      {
        name: 'vyhrát',
        url: '/videos/win.mp4',
      },
      {
        name: 'podzim',
        url: '/videos/autumn.mp4',
      },
    ],
  },
  {
    title: 'Úroveň A2',
    description: <span>K zakoupení ihned za 679 Kč</span>,
    videos: [
      {
        name: 'stín',
        url: '/videos/shadow.mp4',
      },
      {
        name: 'kopec',
        url: '/videos/hill.mp4',
      },
      {
        name: 'smažit',
        url: '/videos/fry.mp4',
      },
      {
        name: 'až do',
        url: '/videos/until.mp4',
      },
    ],
  },
  {
    title: 'Úroveň B1',
    description: <span>K zakoupení od 1. 3. 2024</span>,
    videos: [],
  },
  {
    title: 'Úroveň B2',
    description: <span>K zakoupení od 1. 6. 2024</span>,
    videos: [],
  },
]

export function PrimaryFeatures() {
  const [tabOrientation, setTabOrientation] = useState('horizontal')
  const [video, setVideo] = useState({
    name: 'platit',
    url: '/videos/pay.mp4',
  })
  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    let xlMediaQuery = window.matchMedia('(min-width: 1280px)')

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(xlMediaQuery)
    xlMediaQuery.addEventListener('change', onMediaQueryChange)

    return () => {
      xlMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      // className="relative overflow-hidden bg-blue-600 pb-28 pt-20 sm:py-32"
      className="relative overflow-hidden bg-gradient-to-r from-pd to-pl pb-28 pt-20 sm:py-32"
    >
      {/* <img
        className="absolute left-1/2 top-1/2 max-w-none trangray-x-[-44%] trangray-y-[-42%]"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
      /> */}
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Ukázková videa
          </h2>
          <p className="mt-6 text-lg tracking-tight text-pxl">
            Podívejte se na videa a uvidíte, jak snadno si dokážete slovíčka
            zapamatovat.
          </p>
        </div>
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 xl:grid-cols-12 xl:pt-0"
          vertical={tabOrientation === 'vertical'}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 xl:col-span-3">
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 xl:mx-0 xl:block xl:w-full xl:gap-x-0 xl:gap-y-1 xl:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        'group relative rounded-full px-4 py-1 xl:rounded-l-xl xl:rounded-r-none xl:p-6',
                        selectedIndex === featureIndex
                          ? 'bg-white xl:bg-white/10 xl:ring-1 xl:ring-inset xl:ring-white/10'
                          : 'hover:bg-white/10 xl:hover:bg-white/5',
                      )}
                    >
                      <h3>
                        <Tab
                          className={clsx(
                            'font-display text-lg ui-not-focus-visible:outline-none',
                            selectedIndex === featureIndex
                              ? 'text-p xl:text-white'
                              : 'text-pxl hover:text-white xl:text-white',
                          )}
                          onClick={() => {
                            if (feature.videos.length)
                              setVideo(feature.videos[0])
                            setPlaying(true)
                          }}
                        >
                          <span className="absolute inset-0 rounded-full xl:rounded-l-xl xl:rounded-r-none" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          'mt-2 hidden text-sm xl:block',
                          selectedIndex === featureIndex
                            ? 'text-white'
                            : 'text-pxl group-hover:text-white',
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="xl:col-span-9">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title}>
                    <div className="relative sm:px-6 xl:hidden">
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    {/* <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-gray-50 shadow-xl shadow-blue-900/20 sm:w-auto xl:mt-0 xl:w-[67.8125rem]">
                      <img
                        className="w-full"
                        src={feature.image}
                        alt=""
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div> */}
                    <div className="mt-10 overflow-hidden rounded-xl bg-white/10 shadow-xl sm:w-auto xl:mt-0 xl:h-[36.5rem] xl:ring-1 xl:ring-inset xl:ring-white/10">
                      {feature.videos.length ? (
                        <>
                          <div className="p-4">
                            <ul className="grid grid-cols-2 gap-5 lg:grid-cols-4">
                              {feature.videos.map((video) => (
                                <li
                                  key={video.name}
                                  className="col-span-1 flex"
                                >
                                  <button
                                    className="inline-flex w-full items-center justify-center whitespace-nowrap rounded-md bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 "
                                    onClick={() => {
                                      setVideo(video)
                                      setPlaying(true)
                                    }}
                                  >
                                    <svg
                                      aria-hidden="true"
                                      className="h-3 w-3 flex-none fill-p group-active:fill-current"
                                    >
                                      <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
                                    </svg>
                                    <span className="ml-3">{video.name}</span>
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='aspect-[16/9]'>
                            <ReactPlayer
                              url={video.url}
                              controls
                              loop={true}
                              playing={playing}
                              width="100%"
                              height="100%"
                              className="react-player-example"
                              config={{
                                file: {
                                  attributes: { controlsList: 'nodownload' },
                                },
                              }}
                              onContextMenu={(e) => e.preventDefault()}
                              // onClick={() => setPlaying((prev) => !prev)}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="hidden h-full items-center justify-center p-4 text-white xl:flex">
                          <div className="flex flex-col items-center gap-8">
                            {feature.description}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="h-16 w-16"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  )
}
