import { CallToAction } from '../components/home/CallToAction'
import { Faqs } from '../components/home/Faqs'
import { Hero } from '../components/home/Hero'
import { Quote } from './home/Quote'
import { LiveStory } from './home/LiveStory'
import { PrimaryFeatures } from '../components/home/PrimaryFeatures'
import { SecondaryFeatures } from '../components/home/SecondaryFeatures'
import { Testimonials } from '../components/home/Testimonials'

export default function Home() {
  return (
    <main>
      <Hero />
      <PrimaryFeatures />
      <SecondaryFeatures />
      <CallToAction />
      <Testimonials />
      <Quote />
      <LiveStory />
      <Faqs />
    </main>
  )
}
